<script setup>
import { ref, onMounted } from "vue";

onMounted(() => {

});
</script>

<template>
  <div class="main_content_item">
    <div class="item">
      <h4>活動 A</h4>
      <div class="activity">
        <div class="activity_item">
          <div class="label">
            <h5>活動名稱</h5>
          </div>
          <div class="text">
            <p>
              activity name
            </p>
          </div>
        </div>
        <div class="activity_item">
          <div class="label">
            <h5>活動狀態</h5>
          </div>
          <div class="text">
            <p>
              未參加
            </p>
          </div>
        </div>
        <div class="activity_item">
          <div class="label">
            <h5>顯示天數</h5>
          </div>
          <div class="text">
            <p>
              10天
            </p>
          </div>
        </div>
        <div class="activity_item">
          <div class="label">
            <h5>活動獲利</h5>
          </div>
          <div class="text">
            <p>
              10 ETH
            </p>
          </div>
        </div>
        <div class="activity_item">
          <div class="label">
            <h5>參加</h5>
          </div>
          <div class="text">
              <button>參加</button>
          </div>
        </div>
      </div>
    </div>

    <div class="item">
      <h4>活動 B</h4>
      <div class="activity">
        <div class="activity_item">
          <div class="label">
            <h5>活動名稱</h5>
          </div>
          <div class="text">
            <p>
              activity name
            </p>
          </div>
        </div>
        <div class="activity_item">
          <div class="label">
            <h5>活動狀態</h5>
          </div>
          <div class="text">
            <p>
              未參加
            </p>
          </div>
        </div>
        <div class="activity_item">
          <div class="label">
            <h5>顯示天數</h5>
          </div>
          <div class="text">
            <p>
              10天
            </p>
          </div>
        </div>
        <div class="activity_item">
          <div class="label">
            <h5>活動獲利</h5>
          </div>
          <div class="text">
            <p>
              10 ETH
            </p>
          </div>
        </div>
        <div class="activity_item">
          <div class="label">
            <h5>參加</h5>
          </div>
          <div class="text">
              <button>參加</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<template>
  <header>
    <div id="logo">
      <h1>ETH</h1>
      <img src="@/assets/img/logo.svg" alt="logo">
    </div>
    <div id="h_title">
      <img src="@/assets/img/logo.svg" alt="icon">
      <span>ETH-USDT</span>
    </div>
    <button id="h_link" @click="login_win()">
      <img src="@/assets/img/icon/link_icon.svg" alt="icon">
    </button>
  </header>
</template>

<script setup>
import Swal from 'sweetalert2'
import qs from 'qs'
import { ref } from 'vue'

const ac = ref(null);
const pw = ref(null);

function login_win() {
  // Swal.fire({
  //   title: 'Login',
  //   html: `<div id="login_win">
  //             <div class="item">
  //               <label>Account<input type="text" id="account"></label>
  //             </div>
  //             <div class="item">
  //               <label>Password<input type="password" id="password"></label>
  //             </div>
  //           </div> `,
  //   inputAttributes: {
  //     id: 'account',
  //     id: 'password'
  //   },
  //   confirmButtonText: 'LOGIN',
  //   confirmButtonColor: '#2082fe', // Custom color
  //   preConfirm: () => {
  //     Swal.showLoading(); // Show loading animation
  //     const formData = {
  //       account: document.querySelector('#account').value,
  //       password: document.querySelector('#password').value
  //     };
  //     return axios.post('/login', qs.stringify(formData), {
  //       headers: {
  //         'Content-Type': 'application/x-www-form-urlencoded'
  //       }
  //     })
  //     .then(response => {
  //       Swal.fire({
  //         title: 'Success!',
  //         text: 'Login successful',
  //         icon: 'success'
  //       });
  //     })
  //     .catch(error => {
  //       Swal.fire({
  //         title: 'Error!',
  //         text: 'Login failed',
  //         icon: 'error'
  //       });
  //     });
  //   }
  // });
}
</script>

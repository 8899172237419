<template>
  <div id="home">
    <vheader />
    <main id="home">

      <div id="banner">
        <div class="text">
          <h3>Lossless mining</h3>
          <h3>Liquidity pledge-free</h3>
          <h2>Reward <span>1 Million</span> ETH</h2>
        </div>
        <img src="@/assets/img/home/banner/banner.jpg" alt="banner">
      </div>

      <div id="main_label">
        <div class="wrapper">
          <div class="item" :class="{active:main_label == 0}" @click="main_label = 0">
            <h2>礦池</h2>
          </div>
          <div class="item" :class="{active:main_label == 1}" @click="main_label = 1">
            <h2>帳戶</h2>
          </div>
          <div class="item" :class="{active:main_label == 2}" @click="main_label = 2">
            <h2>活動</h2>
          </div>
          <div class="line" ref="main_label_line" />
        </div>
      </div>

      <div id="main_content">
        <div class="wrapper">
          <mining v-if="main_label == 0" />
          <account v-else-if="main_label == 1" />
          <activity v-else />
        </div>
      </div>

    </main>
  </div>
</template>

<script setup>
import vheader from "@/components/header.vue";
import mining from "@/components/mining.vue";
import account from "@/components/account.vue";
import activity from "@/components/activity.vue";
// import vfooter from "@/components/footer.vue";
import { ref, onMounted,watch } from "vue";

const main_label = ref(0);
const main_label_line = ref(null);

watch(
    () => main_label.value,
    () => {
      main_label_line.value.style.left=`${33.333333 * main_label.value}%`
    }
);

onMounted(() => {

});
</script>

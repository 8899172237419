<script setup>
import { ref, onMounted } from "vue";

const son_menu = ref(0);

onMounted(() => {});
</script>

<template>
  <div class="main_content_item">
    <div class="item">
      <h4>My account</h4>
      <ul class="content">
        <li>
          <div class="label">Wallet balance</div>
          <div class="item_content color">USDT</div>
        </li>
        <li>
          <div class="label">Exchangeable</div>
          <div class="item_content">ETH</div>
        </li>
        <li>
          <div class="label">Withdrawable</div>
          <div class="item_content">USDT</div>
        </li>
      </ul>
    </div>

    <div class="item">
      <div id="son_label">
        <div class="son_label_item" @click="son_menu = 0" :class="{active:son_menu == 0}">
          <h3>Exchange</h3>
        </div>
        <div class="son_label_item" @click="son_menu = 1" :class="{active:son_menu == 1}">
          <h3>Withdraw</h3>
        </div>
        <div class="son_label_item" @click="son_menu = 2" :class="{active:son_menu == 2}">
          <h3>Record</h3>
        </div>
      </div>
      <div id="son_content">

        <h4 v-if="son_menu == 0">Exchange</h4>
        <h4 v-else-if="son_menu == 1">Withdraw</h4>
        <h4 v-else>Record</h4>

        <div class="change_currency">

          <div v-if="son_menu == 0" class="change_currency_content_item">
            <div class="change_currency_item">
              <input type="text" />
              <div class="change_icon">
                <img src="@/assets/img/icon/transfer.svg" alt="icon" />
              </div>
              <div class="currency">
                <img src="@/assets/img/icon/usdt.png" alt="currency_icon" />
                <span> USDT </span>
              </div>
            </div>
            <div class="change_btn">
              <button>交換</button>
              <button>全部交換</button>
              <p>Convert the ETH coins you dug into USDT</p>
            </div>
          </div>

          <div v-else-if="son_menu == 1" class="change_currency_content_item">
            <div class="change_currency_item">
              <input type="text" />
              <div class="change_icon">
                <img src="@/assets/img/icon/transfer.svg" alt="icon" />
              </div>
              <div class="currency">
                <img src="@/assets/img/icon/usdt.png" alt="currency_icon" />
                <span> USDT </span>
              </div>
            </div>
            <div class="change_btn">
              <button>交換</button>
              <button>全部交換</button>
              <p>Your withdraw will be sent to your USDT wallet address within 24 hours in the future</p>
            </div>
          </div>

          <div v-else class="change_currency_content_item">
            
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted } from "vue";

const qa = ref([
  {
    id: 1,
    open: true,
    q: "How do I need to join？",
    a: "Participating in non-destructive and non-guaranteed liquidity mining requires payment of ETH miner fees to receive the replacement gold coupons, and the ETH wallet address only needs to be claimed once. Automatically open mining permissions after success."
  },
  {
    id: 2,
    open: false,
    q: "How do I need to join？",
    a: "Participating in non-destructive and non-guaranteed liquidity mining requires payment of ETH miner fees to receive the replacement gold coupons, and the ETH wallet address only needs to be claimed once. Automatically open mining permissions after success."
  },
  {
    id: 3,
    open: false,
    q: "How do I need to join？",
    a: "Participating in non-destructive and non-guaranteed liquidity mining requires payment of ETH miner fees to receive the replacement gold coupons, and the ETH wallet address only needs to be claimed once. Automatically open mining permissions after success."
  },
  {
    id: 4,
    open: false,
    q: "How do I need to join？",
    a: "Participating in non-destructive and non-guaranteed liquidity mining requires payment of ETH miner fees to receive the replacement gold coupons, and the ETH wallet address only needs to be claimed once. Automatically open mining permissions after success."
  },
  {
    id: 5,
    open: false,
    q: "How do I need to join？",
    a: "Participating in non-destructive and non-guaranteed liquidity mining requires payment of ETH miner fees to receive the replacement gold coupons, and the ETH wallet address only needs to be claimed once. Automatically open mining permissions after success."
  },
  {
    id: 6,
    open: false,
    q: "How do I need to join？",
    a: "Participating in non-destructive and non-guaranteed liquidity mining requires payment of ETH miner fees to receive the replacement gold coupons, and the ETH wallet address only needs to be claimed once. Automatically open mining permissions after success."
  },
  {
    id: 7,
    open: false,
    q: "How do I need to join？",
    a: "Participating in non-destructive and non-guaranteed liquidity mining requires payment of ETH miner fees to receive the replacement gold coupons, and the ETH wallet address only needs to be claimed once. Automatically open mining permissions after success."
  },
  {
    id: 8,
    open: false,
    q: "How do I need to join？",
    a: "Participating in non-destructive and non-guaranteed liquidity mining requires payment of ETH miner fees to receive the replacement gold coupons, and the ETH wallet address only needs to be claimed once. Automatically open mining permissions after success."
  },
  {
    id: 9,
    open: false,
    q: "How do I need to join？",
    a: "Participating in non-destructive and non-guaranteed liquidity mining requires payment of ETH miner fees to receive the replacement gold coupons, and the ETH wallet address only needs to be claimed once. Automatically open mining permissions after success."
  },
  {
    id: 10,
    open: false,
    q: "How do I need to join？",
    a: "Participating in non-destructive and non-guaranteed liquidity mining requires payment of ETH miner fees to receive the replacement gold coupons, and the ETH wallet address only needs to be claimed once. Automatically open mining permissions after success."
  }
]);

function open_btn(data){

  qa.value.forEach((item)=>{
    item.open = false;
  })

  qa.value[data].open = true;
}

onMounted(() => {

});
</script>

<template>
  <div class="main_content_item">

    <div class="item">
      <h4>Pool data</h4>
      <ul class="content">
        <li>
          <div class="label">Total output</div>
          <div class="item_content color">ETH</div>
        </li>
        <li>
          <div class="label">Valid node</div>
          <div class="item_content"></div>
        </li>
        <li>
          <div class="label">Participant</div>
          <div class="item_content"></div>
        </li>
        <li>
          <div class="label">User Revenue</div>
          <div class="item_content">USDC</div>
        </li>
      </ul>
    </div>

    <div class="title">
      <h3>
        Mining
      </h3>
      <p>
        Liquidity mining income
      </p>
    </div>

    <div class="item">
      <h4>Supercomputing miner</h4>
      <ul class="content">
        <li>
          <div class="label top_title">Miner</div>
          <div class="item_content top_title">
            <div class="top_item">
              Value
            </div>
            <div class="top_item">
              Yield
            </div>
          </div>
        </li>
        <li>
          <div class="label">FPGA</div>
          <div class="item_content bottom">
            <div class="bottom_item">
              500~9999
            </div>
            <div class="bottom_item">
              0.46% ~ 0.53%
            </div>
          </div>
        </li>

        <li>
          <div class="label">IPFS</div>
          <div class="item_content bottom">
            <div class="bottom_item">
              10000~99999
            </div>
            <div class="bottom_item">
              0.95% ~ 1.02%
            </div>
          </div>
        </li>

        <li>
          <div class="label">ASIC</div>
          <div class="item_content bottom">
            <div class="bottom_item">
              50w+
            </div>
            <div class="bottom_item">
              1.98% ~ 2.05%
            </div>
          </div>
        </li>

        <li>
          <div class="label">Valid node</div>
          <div class="item_content bottom">
            <div class="bottom_item">
              500~9999
            </div>
            <div class="bottom_item">
              0.46% ~ 0.53%
            </div>
          </div>
        </li>

      </ul>
    </div>

    <div class="item">
      <h4>User Output</h4>

      <ul class="content">
        <li>
          <div class="label top_title">Address</div>
          <div class="item_content top_title">
            <div class="top_item">
              Qantity
            </div>
          </div>
        </li>
      </ul>

      <marquee direction="up">
        <ul class="content marquee">
          <li>
            <div class="label">0x3C...75a40C</div>
            <div class="item_content bottom">
              <div class="bottom_item">
                5.4314 ETH
              </div>
            </div>
          </li>
          <li>
            <div class="label">0x3C...75a40C</div>
            <div class="item_content bottom">
              <div class="bottom_item">
                5.4314 ETH
              </div>
            </div>
          </li>
          <li>
            <div class="label">0x3C...75a40C</div>
            <div class="item_content bottom">
              <div class="bottom_item">
                5.4314 ETH
              </div>
            </div>
          </li>
          <li>
            <div class="label">0x3C...75a40C</div>
            <div class="item_content bottom">
              <div class="bottom_item">
                5.4314 ETH
              </div>
            </div>
          </li>
          <li>
            <div class="label">0x3C...75a40C</div>
            <div class="item_content bottom">
              <div class="bottom_item">
                5.4314 ETH
              </div>
            </div>
          </li>
          <li>
            <div class="label">0x3C...75a40C</div>
            <div class="item_content bottom">
              <div class="bottom_item">
                5.4314 ETH
              </div>
            </div>
          </li>
          <li>
            <div class="label">0x3C...75a40C</div>
            <div class="item_content bottom">
              <div class="bottom_item">
                5.4314 ETH
              </div>
            </div>
          </li>
          <li>
            <div class="label">0x3C...75a40C</div>
            <div class="item_content bottom">
              <div class="bottom_item">
                5.4314 ETH
              </div>
            </div>
          </li>
          <li>
            <div class="label">0x3C...75a40C</div>
            <div class="item_content bottom">
              <div class="bottom_item">
                5.4314 ETH
              </div>
            </div>
          </li>
          <li>
            <div class="label">0x3C...75a40C</div>
            <div class="item_content bottom">
              <div class="bottom_item">
                5.4314 ETH
              </div>
            </div>
          </li>
          <li>
            <div class="label">0x3C...75a40C</div>
            <div class="item_content bottom">
              <div class="bottom_item">
                5.4314 ETH
              </div>
            </div>
          </li>
          <li>
            <div class="label">0x3C...75a40C</div>
            <div class="item_content bottom">
              <div class="bottom_item">
                5.4314 ETH
              </div>
            </div>
          </li>
        </ul>
      </marquee>
    </div>

    <div class="item">
      <h4>Guide video</h4>
      <div class="video">
        <div class="video_content">
          <video src="@/assets/video/d95dd301.mp4"></video>
        </div>
      </div>
    </div>

    <div class="title">
      <h3>
        Help center
      </h3>
      <p>
        Hope it helps you
      </p>
    </div>

    <div class="item">
      <div id="qa">
        <ul>
          <li v-for="(item, index) in qa" :class="{active:item.open}" :key="item.id" :data-index="index">
            <div class="qa_title" @click="open_btn(index)">
              <span>{{ item.q }}</span><i />
            </div>
            <div class="qa_content">
              <p>{{ item.a }}</p>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="title">
      <h3>
        Audit report
      </h3>
      <p>
        We have a secure audit report
      </p>
    </div>

    <div class="item">
      <div id="audit">
        <div class="item_audit">
          <img src="http://placehold.jp/200x125.png" alt="pic">
        </div>
        <div class="item_audit">
          <img src="http://placehold.jp/200x125.png" alt="pic">
        </div>
        <div class="item_audit">
          <img src="http://placehold.jp/200x125.png" alt="pic">
        </div>
        <div class="item_audit">
          <img src="http://placehold.jp/200x125.png" alt="pic">
        </div>
        <div class="item_audit">
          <img src="http://placehold.jp/200x125.png" alt="pic">
        </div>
        <div class="item_audit">
          <img src="http://placehold.jp/200x125.png" alt="pic">
        </div>
        <div class="item_audit">
          <img src="http://placehold.jp/200x125.png" alt="pic">
        </div>
        <div class="item_audit">
          <img src="http://placehold.jp/200x125.png" alt="pic">
        </div>
        <div class="item_audit">
          <img src="http://placehold.jp/200x125.png" alt="pic">
        </div>
        <div class="item_audit">
          <img src="http://placehold.jp/200x125.png" alt="pic">
        </div>
      </div>
    </div>

  </div>
</template>
